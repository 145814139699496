import type { ImageVariant } from '@ori/image-sizes-helper';
import { getImageVariants } from '@ori/image-sizes-helper';

import { CAROUSEL_IMAGE_SIZES_VARIANTS } from '../constants';
import type { HeroBanner } from '../models/graphql-types/net';

export interface GetResponsiveImageVariantsOptions {
  /** Desktop image url */
  desktopUrl: NonNullable<HeroBanner['imageUrl']>;

  /** Mobile image url */
  mobileUrl?: HeroBanner['smallImageUrl'];

  /** Background color of image - may be useful when the image is not loaded or is loading */
  backgroundColor?: string;
}

export interface ResponsiveImageVariant {
  /**
   * Image for given range
   */
  url: ImageVariant['url'];
  /**
   * Range from what width should url apply
   */
  from?: number;
  /**
   * Range to what width should url apply
   */
  to?: number;
}

/**
 * Enhancement of `getImageVariants` from `@ori/image-sizes-helper` package. Combines `desktopUrl` and `mobileUrl` (defaults to `desktopUrl`) for the final image.
 */
export const getResponsiveImageVariants = ({
  desktopUrl,
  mobileUrl,
  backgroundColor,
}: GetResponsiveImageVariantsOptions) => {
  const sanizitedDesktopUrl = new URL(desktopUrl);
  const sanizitedMobileUrl = mobileUrl ? new URL(mobileUrl) : sanizitedDesktopUrl;
  sanizitedDesktopUrl.searchParams.delete('inputFormat');
  sanizitedMobileUrl.searchParams.delete('inputFormat');
  const desktopVariants = getImageVariants({
    url: sanizitedDesktopUrl.toString(),
    backgroundColor,
    imageFormat: 'WebP',
    imageSizeMultiplier: 1,
    params: CAROUSEL_IMAGE_SIZES_VARIANTS.DESKTOP,
  });

  const mobileVariants = getImageVariants({
    // Sometimes smallImageUrl may not be defined - for that we fall back to imageUrl
    url: sanizitedMobileUrl.toString(),
    backgroundColor,
    imageFormat: 'WebP',
    imageSizeMultiplier: 1,
    params: CAROUSEL_IMAGE_SIZES_VARIANTS.MOBILE,
  });

  return [...mobileVariants, ...desktopVariants].map(({ url, width }, index, allVariants) => {
    const result: ResponsiveImageVariant = {
      url,
    };

    const isFirst = index === 0;
    const nextVariant = allVariants[index + 1];
    const isLast = nextVariant === undefined;

    // We will generate specific ranges to apply specific url
    if (isFirst) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- its for sure there, because there are more than one variant
      result.to = nextVariant!.width - 0.1;
    } else {
      result.from = width;

      if (nextVariant) {
        result.url = nextVariant.url;
      }

      if (!isLast) {
        result.to = nextVariant.width - 0.1;
      }
    }

    return result;
  });
};
