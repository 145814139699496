import type { BannerData } from '@ori/analytics-data';

import type { BestOffersBannersFragment, Maybe } from '../models/graphql-types/net';

export const analyticsBannersMapper = (banners: Array<Partial<Maybe<BestOffersBannersFragment>>>): BannerData[] =>
  banners
    .filter((banner) => banner !== null)
    .map(({ headline, imageUrl, link }) => ({
      localBannerTitle: headline ?? '',
      media: imageUrl ? [imageUrl] : [],
      target: link?.relativeUrl ?? '',
    }));
