import * as OpenProductInfoDialog from '@ori-events/open-product-info-dialog';
import { Placement } from '@ori/analytics-data';

import type { Product } from '../models/graphql-types/net';

export const openProductInfoDialog =
  (productCode: Product['productCode'], dialogMode: OpenProductInfoDialog.DialogMode) => () =>
    window.dispatchEvent(
      new CustomEvent<OpenProductInfoDialog.Data>(OpenProductInfoDialog.Name, {
        detail: {
          mode: dialogMode,
          placement: Placement.NewProducts,
          productCodes: [productCode],
        },
      }),
    );
